.Table {
  width: 100%;
  border-spacing: 0px;
  position: relative;
  border-collapse: collapse;
}

.TableContainer {
  width: 100%;
  overflow-y: auto;
  position: relative;
  max-height: 600px;
}

.Headers {
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: right;
  font-weight: 500;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  text-wrap: nowrap;
  width: fit-content;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.Headers::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-stroke);
}

.Headers:last-child {
  padding-right: 15px;
}

.Cells {
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  width: fit-content;
  cursor: pointer;
  padding-right: 30px;
}
.Headers:first-child {
  padding-left: 15px;
  position: sticky;
  left: 0;
  z-index: 20;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.Cells:first-child {
  padding-left: 15px;
  position: sticky;
  left: 0;
  z-index: 5;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
.Cells:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: var(--color-stroke);
}

.Cells:last-child {
  padding-right: 15px;
}

.Cells > p {
  margin: 0px;
}

.rowsCell {
  border-bottom: 1px solid var(--color-gray-stroke);
}

.rowsCell:hover {
  background-color: var(--color-gray-5);
  overflow: hidden;
}

.rowsCell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Noresults {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 5px;
}

.Noresults > h2,
.Noresults > p {
  margin: 0px;
}

.Noresults > h2 {
  font-size: 38px;
  font-weight: 500;
}

.Noresults > p {
  font-size: 18px;
}
