.FiltersWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  /* position: fixed;
  bottom: 20px;
  left: 0;
  right: 0; */
}

.FiltersContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(255, 255, 255, 0.16);
}

.ColumnModalWrapper {
  position: relative;
}

.ColumnModal {
  position: absolute;
  top: 40px;
  left: -10px;
  right: 0;
  z-index: 10000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: fit-content;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.97);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(255, 255, 255, 0.16);
}

.CheckboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  gap: 5px;
}
.CheckboxWrapper:hover {
  opacity: 0.8;
}

.CheckboxWrapper label {
  font-size: 15px;
  color: var(--color-gray-50);
  cursor: pointer;
}

.ColumnButton {
  border-radius: 6px;
  /* width: 100%; */
  min-width: 120px;
  max-width: fit-content;
  padding: 8px 12px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid var(--color-stroke);
  border-radius: 5px;

  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.ColumnButton:hover {
  opacity: 0.7;
}

.Checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 7px;
  background-color: white;
  transition: background-color 0.2s, border-color 0.2s;

  &:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.Checkbox:checked::before {
  content: url("../../../images/check.svg");
  display: block;
  text-align: center;
  color: white;
  font-size: 16px;
}
