.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.HeroContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 50px;
  padding: 40px 20px;
}

.HeroText {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
}

.HeroText > h3,
.HeroText > p {
  margin: 0px;
}

.HeroText > h3 {
  font-size: 38px;
}

.HeroText > p {
  font-size: 16px;
}

.Loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.AppContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.MainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* max-width: 1100px; */
}
.ModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.ModalBody > p {
  margin: 0px;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.TotalCompanies {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: var(--color-gray-50);
}

.LabelsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Label {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Label p {
  margin: 0;
}
.Label div {
  width: 15px;
  height: 15px;
  border-radius: 999px;
}

.RedLabel {
  background-color: rgb(251, 72, 72);
}

.BlueLabel {
  background-color: rgb(72, 117, 251);
}
.GreenLabel {
  background-color: rgb(50, 198, 84);
}

.PieChart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
}

.Red {
  color: rgb(251, 72, 72);
  background-color: rgb(255, 233, 233);
  text-align: left;
  width: fit-content;
  height: fit-content;
  padding: 2px 4px;
  border-radius: 5px;
}

.Blue {
  color: rgb(72, 117, 251);
  background-color: rgb(233, 244, 255);
  text-align: left;
  width: fit-content;
  height: fit-content;
  padding: 2px 4px;
  border-radius: 5px;
}
.Green {
  color: rgb(50, 198, 84);
  background-color: rgb(213, 255, 223);
  text-align: left;
  width: fit-content;
  height: fit-content;
  padding: 2px 4px;
  border-radius: 5px;
}

.TableWrapper {
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
  height: fit-content;
  padding: 20px;
  margin-bottom: 60px;
  overflow: hidden;
  gap: 10px;
}
.TableContent {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--color-stroke);
}

.ColumnsSelectionWrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.ColumnsSelection {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.ColumnsSelectionOutsideWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.ColumnsSelectionOutsideWrapper h5 {
  text-align: left;
  width: 100%;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: var(--color-gray-90);
}
.ColumnsSelectionOutsideWrapper > p {
  text-align: left;
  width: 100%;
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: var(--color-gray-60);
}

.Required {
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: var(--color-error);
}

.TableHeaders {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.SymbolWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 5px 0px;
}
.SymbolText {
  font-weight: 500;
  font-size: 13px;
  color: var(--color-primary-90);
  text-decoration: underline;
  margin: 0;
}
.SymbolText:hover,
.CompanyName:hover {
  opacity: 0.8;
  cursor: pointer;
  z-index: 0;
}

.CompanyName {
  font-weight: 400;
  font-size: 13px;
  color: gray;
  text-decoration: none;
  margin: 0px;
  text-align: left;
}

.InputHolder {
  border: 1px solid black;
  width: 150px;
  padding: 2px;
  border-radius: 4px;
}

.PaginationWrapper {
  /* margin-top: 20px; */
  padding: 20px;
}

.SuccessMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-success-25);
  border: 1px solid var(--color-success-90);
  border-radius: 10px;
  padding: 5px;
}

.SuccessMessage p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-success-90);
}

.CompanyImg {
  width: 100%;
  border-radius: 5px;
}

.EventScenarioImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid var(--color-stroke);
  padding: 6px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.EventScenarioImg:hover {
  opacity: 0.7;
}

.FiltersWrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 20px;
}

.ErrorText {
  width: 100%;
  text-align: left;
  margin: 0;
  font-size: 13px;
  color: var(--color-error);
}

@media screen and (max-width: 700px) {
  .ColumnsSelectionWrapper {
    flex-direction: column;
    gap: 0;
  }
  .HeroContent {
    flex-direction: column;
  }
}
